import { useEffect, useState } from 'react'
import useVacancyService from '../../services/vacancy'
import VacancyList from './VacancyList'
import { useSelector } from 'react-redux'

const VacancyPage = () => {
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const vacancyServive = useVacancyService()
  const [vacancies, setVacancies] = useState([])
  const refresh = () => {
    vacancyServive.getAll().then((data) => setVacancies(data.vacancies))
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <div className="m-t">
      <h3 className="big-title m-d">{lang.vacancies}</h3>
      <div className="row between m-d no-wrap">
        <div className="column">
          <VacancyList vacancies={vacancies} />
        </div>
      </div>
    </div>
  )
}
export default VacancyPage
