import UserContext from '../../contexts/userContext'
import { useContext, useEffect, useState } from 'react'
import useUserService from '../../services/user'
import useField from '../../hooks/useField'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useToast from '../../util/promiseToast'
import { Person } from '@styled-icons/evaicons-solid/Person'
import { Phone } from '@styled-icons/boxicons-solid/Phone'
import { Home } from '@styled-icons/boxicons-solid/Home'
import Input from '../basic/Input'
import { Edit } from '@styled-icons/evaicons-solid/Edit'
import { Trash } from '@styled-icons/boxicons-regular/Trash'
import { useCartServiceDispatch } from '../../reducers/cartReducer'

const AddressForm = ({ submit, values, cancel, withoutUser }) => {
  const dispatch = useDispatch()
  const { changeCourrierAddress } = useCartServiceDispatch()
  const courrierAddress = useSelector((state) =>
    state.cart.localCart ? state.cart.courrierAddress : undefined
  )
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const name = useField('text')
  const surname = useField('text')
  const phone = useField('phone')
  const city = useField('text')
  const street = useField('text')
  const house = useField('text')
  const apartment = useField('text')
  const postIndex = useField('text')

  const onBlur = (change) => {
    if (withoutUser) {
      dispatch(changeCourrierAddress({ ...courrierAddress, ...change }))
    }
  }

  useEffect(() => {
    if (values) {
      name.changeValue(values.name)
      surname.changeValue(values.surname)
      phone.changeValue(values.phone)
      city.changeValue(values.city)
      street.changeValue(values.street)
      house.changeValue(values.house)
      apartment.changeValue(values.apartment)
      postIndex.changeValue(values.postIndex)
    } else if (withoutUser && courrierAddress) {
      name.changeValue(courrierAddress.name)
      surname.changeValue(courrierAddress.surname)
      phone.changeValue(courrierAddress.phone)
      city.changeValue(courrierAddress.city)
      street.changeValue(courrierAddress.street)
      house.changeValue(courrierAddress.house)
      apartment.changeValue(courrierAddress.apartment)
      postIndex.changeValue(courrierAddress.postIndex)
    }
  }, [values])

  const onSubmit = (event) => {
    event.preventDefault()
    if (
      !name.value ||
      !surname.value ||
      !phone.value ||
      !city.value ||
      !street.value ||
      !postIndex.value
    ) {
      toast.error(lang.toast_all_fields_required)
    } else {
      submit({
        name: name.value,
        surname: surname.value,
        phone: phone.value,
        city: city.value,
        street: street.value,
        house: house.value,
        apartment: apartment.value,
        postIndex: postIndex.value,
        id: values && values.id,
      })
      name.clear()
      surname.clear()
      phone.clear()
      city.clear()
      street.clear()
      house.clear()
      apartment.clear()
      postIndex.clear()
    }
  }
  return (
    <form
      className="column align-cross-end no-gap"
      onSubmit={onSubmit}>
      <Input
        value={lang.latvia}
        disabled
        label={lang.country}
        width="350px"
      />
      <Input
        label={lang.city}
        required
        width="350px"
        onBlur={() => {
          onBlur({ city: city.value })
        }}
        {...city}
      />
      <div
        className="row"
        style={{ width: '350px' }}>
        <div style={{ flex: '1 1 150px' }}>
          <Input
            label={lang.street}
            required
            onBlur={() => {
              onBlur({ street: street.value })
            }}
            {...street}
            width="unset"
          />
        </div>
        <div style={{ flex: '1 1 150px' }}>
          <Input
            label={lang.post_index}
            required
            onBlur={() => {
              onBlur({ postIndex: postIndex.value })
            }}
            {...postIndex}
            width="unset"
          />
        </div>
      </div>
      <div
        className="row"
        style={{ width: '350px' }}>
        <div style={{ flex: '1 1 150px' }}>
          <Input
            label={lang.house}
            width="unset"
            {...house}
            onBlur={() => {
              onBlur({ house: house.value })
            }}
          />
        </div>
        <div style={{ flex: '1 1 150px' }}>
          <Input
            label={lang.apt}
            width="unset"
            {...apartment}
            onBlur={() => {
              onBlur({ apartment: apartment.value })
            }}
          />
        </div>
      </div>
      <div
        className="row"
        style={{ width: '350px' }}>
        <div style={{ flex: '1 1 150px' }}>
          <Input
            required
            label={lang.name}
            width="unset"
            onBlur={() => {
              onBlur({ name: name.value })
            }}
            {...name}
          />
        </div>
        <div style={{ flex: '1 1 150px' }}>
          <Input
            required
            label={lang.surname}
            width="unset"
            onBlur={() => {
              onBlur({ surname: surname.value })
            }}
            {...surname}
          />
        </div>
      </div>
      <Input
        required
        width="350px"
        label={lang.phone}
        {...phone}
        onBlur={() => {
          onBlur({ phone: phone.value })
        }}
      />
      {!withoutUser && (
        <>
          {!values ? (
            <button
              className="btn m-t"
              type="submit">
              {lang.add_new_address}
            </button>
          ) : (
            <div className="row m-t">
              <button
                className="btn"
                type="submit">
                {lang.save_short}
              </button>
              <button
                className="btn cancel"
                onClick={cancel}>
                {lang.cancel}
              </button>
            </div>
          )}
        </>
      )}
    </form>
  )
}
export default AddressForm
