import { useSelector } from 'react-redux'

const Vacancy = ({ vacancy }) => {
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  return (
    <div>
      <h3 className="big-title m-d">{vacancy.title}</h3>
      <h3 className="title accented m-d">
        {lang.salary}: &euro;{vacancy.salaryMin}-&euro;{vacancy.salaryMax}
      </h3>
      <div
        dangerouslySetInnerHTML={{ __html: vacancy.text }}
        className="font-big"></div>
    </div>
  )
}
export default Vacancy
