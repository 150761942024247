import styled from 'styled-components'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { useEffect } from 'react'

const Recenter = ({ latlon }) => {
  const map = useMap()
  useEffect(() => {
    map.setView(latlon)
  }, [latlon])
}

const Map = ({ selected_marker_coordinates, all_markers, onMarkerClick }) => {
  return (
    <>
      <div
        className="card"
        style={{ flex: '10 0 60%' }}>
        <div className="map-container">
          <MapContainer
            style={{ height: '100%', width: '100%' }}
            center={selected_marker_coordinates || ['56.8796', '24.5032']}
            zoom={8}
            scrollWheelZoom={true}>
            <TileLayer
              zIndex={2}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {all_markers?.map((marker) => (
              <Marker
                key={marker.id}
                position={marker.coordinates}
                opacity={0.3}
                eventHandlers={{
                  click: () => {
                    onMarkerClick(marker.id)
                  },
                }}
              />
            ))}

            {selected_marker_coordinates && (
              <>
                <Marker position={selected_marker_coordinates} />
                <Recenter latlon={selected_marker_coordinates} />
              </>
            )}
          </MapContainer>
        </div>
      </div>
    </>
  )
}
export default Map
