import styled from 'styled-components'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { useEffect } from 'react'
import Map from './Map'

const Recenter = ({ latlon }) => {
  const map = useMap()
  useEffect(() => {
    map.setView(latlon)
  }, [latlon])
}

const AddressWithMap = ({ latlon, ...props }) => {
  return (
    <>
      {latlon && (
        <div className="row no-row-gap">
          <Map selected_marker_coordinates={latlon} />
          {props.children && (
            <div
              className="card p"
              style={{ flex: '1 1 320px' }}>
              {props.children}
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default AddressWithMap
