const en = {
  products: 'Products',
  about: 'about',
  contact: 'contact',
  sign_in: 'sign in',
  sign_up: 'sign up',
  new_product: 'new product',
  management: 'management',
  categories: 'Categories',
  sign_out: 'Sign out',
  sum: 'Sum',
  paid_delivery: 'Delivery',
  under: 'under €',
  total: 'Total',
  order: 'Order',
  city: 'City',
  street: 'Street',
  house: 'House',
  apt: 'Apartment',
  name: 'Name',
  surname: 'Surname',
  phone: 'Phone',
  add_new_address: 'add new address',
  proceed_to_payment: 'go to payment',
  contact_us: 'contact us',
  email: 'Email',
  message: 'Message',
  send_message: 'Send message',
  buy: 'buy',
  add_to_cart: 'add to cart',
  nutritional_info: 'Nutritional info',
  g_contains: '100g Contains',
  energy_content: 'Energy content',
  fat: 'Fat',
  of_which_saturated_fat: 'of which saturated fat',
  carbohydrates: 'Carbohydrates',
  of_which_sugars: 'of which sugars',
  fiber: 'Fiber',
  protein: 'Protein',
  salt: 'Salt',
  EAN_code: 'EAN code',
  about_us: 'about us',
  values: 'our values',
  nature: 'nature',
  people: 'people',
  quality: 'quality',
  how_products_are_made: "how flora bakery's products are made",
  product: 'product',
  category: 'category',
  select_existing_categories: 'Select existing categories',
  select_existing_products: 'Select existing products',
  add_category: 'Add a category',
  add_new_category: 'Add a new category',
  product_name: 'Name',
  image_url: 'Image url',
  cancel: 'Cancel',
  add: 'Add',
  or: 'Or',
  add_new_product: 'Add a new product',
  add_product: 'Add a product',
  description: 'Description',
  ingredients: 'Ingredients',
  weight: 'Weight',
  in_grams: 'in grams',
  price: 'Price',
  in_cents: 'cents',
  add_to_all: "Add to category 'all'",
  add_to_new: "Add to category 'new'",
  accept_order: 'Accept order?',
  accept: 'accept',
  refuse: 'refuse',
  ready_for_delivery: 'Ready for delivery?',
  ready: 'ready',
  out_for_delivery: 'Out for delivery?',
  deliver: 'deliver',
  delivery_completed: 'Delivery completed?',
  completed: 'completed',
  order_status: {
    placed: 'Placed',
    ready_for_pickup: 'Ready',
    ready_for_delivery: 'Deliverable',
    waiting_for_courrier: 'Awaiting courrier',
    delivering: 'Delivering',
    completed: 'Completed',
    invoiced: 'Invoiced',
    refunded: 'Refunded',
    dropped_in_pickup_point: 'Dropped in pickup point',
    picked_up_by_courrier: 'Picked up by courrier',
    delivered_to_pickup_point: 'Delivered to pickup point',
  },
  last_modified_by: 'last modified by',
  confirm_delete_product: 'Are you sure you want to delete this product?',
  confirm_save_changes: 'Are you sure you want to save these changes?',
  save_changes: 'save changes',
  discard_changes: 'discard changes',
  edit_product: 'edit product',
  delete_product: 'delete product',
  empty_cart: 'Your shopping cart is empty.',
  orders: 'Orders',
  username: 'username',
  password: 'Password',
  about_text: [
    'Flora Bakery was founded back in the 1990s when it put its first homemade loaf of white bread on the festive table at Christmas time. The bakery’s name has historical origins. Before focusing on making delicious baked products and pastries, the owners grew flowers. This inspired them to give their next line of business a name connected with nature. The bakery was named Flora.',
    'In 2008, Flora opened its new EU standard production plan in Krimulda Parish in a mythical place named Ragana. Here all products are produced at one site. However, in this modern bakery, our greatest asset is still the fact that our products are handcrafted, which we combine with years of knowledge of how to bake the best quality products.',
    'In April 2012, Flora Bakery received a BIO product production certificate.',
    'Fragrant, organic, natural ingredients are among the core values of Flora Bakery, whereby nature and its colours are the source of inspiration, which created the Flora Bakery. Picture the scenery, Latvia’s blossoming meadows adorned by playful red poppies and golden ears, radiating in the glowing sun. Just as an perfumer takes the most captivating aromas to create perfumes, we seek the highest quality ingredients as well as unique herbs and spices. Flora products are made nurturing the knowledge and know-how of our expert bakers. We offer our customers an intimate bouquet of flavour created right here in the picturesque Latvian parish of Krimulda. May the floral delights blossom on your table!',
  ],
  create: 'create',
  verify_email: 'Verify email',
  verification_instructions:
    'Open your email and follow the instructions to verify your email and start shopping',
  password_must: 'Password must',
  password_8_chars: 'Must be at least 8 characters long',
  password_contain_digit: 'Must contain at least one digit',
  password_contain_special: 'Must contain at least one of !@#$%^&*',
  password_contain_letter: 'Must contain at least one letter',
  toast_all_fields_required: 'All fields are required',
  toast_address_added: 'The address has been added',
  toast_sending: 'Sending',
  toast_success: 'Success!',
  toast_failed: 'Failed to do the thing',
  toast_order_successful: 'Your order has been placed!',
  toast_unauthorized: 'You are not allowed to do that',
  email_required: 'Email is required',
  password_required: 'Password is required',
  email_already_used: 'This email is already registered',
  already_have_account: 'Already have an account? Sign in.',
  dont_have_account: "Don't have an account yet? Sign up here.",
  invalid_credentials: 'Email or password is incorrect',
  with_VAT: 'VAT incl.',
  expiration_time: 'Product is good for',
  after_opening: 'After opening use within',
  days: 'days',
  months: 'months',
  save: 'Save changes',
  new: 'New product',
  vegan: 'Vegan',
  high_protein: 'High in protein',
  high_fiber: 'High in fiber',
  handmade: 'Handmade',
  no_added_yeast: 'No added yeast',
  no_conservants: 'No added conservants',
  vitamin_d: 'Source of vitamin D',
  iodine: 'Source of iodine',
  d3: 'Vitamin D3',
  toast_changes_saved: 'Changes have been saved!',
  toast_product_created: 'The product has been created!',
  discounts: 'Discounts',
  found_categories: 'Found categories',
  found_products: 'Found products',
  create_discount: 'Discount',
  remove_discount: 'Remove discount',
  discount_price: 'Discounted price',
  start_date: 'Start date',
  end_date: 'End date',
  without_vat_cents: 'without VAT, in cents',
  confirm_remove_discount: 'Are you sure you want to remove the discount?',
  toast_discount_created: 'The discount has been created!',
  toast_discount_removed: 'The discount has been removed!',
  eu_projects: 'EU Projects',
  distance_agreement: 'Distance agreement',
  sales: 'Sales',
  production: 'Production',
  sales_manager: 'Sales Manager',
  deputy_sales_manager: 'Deputy Sales Manager',
  export_manager: 'Export Manager',
  bread_manager: 'Bread Production and Quality Manager',
  pastry_manager: 'Pastry Production and Quality Manager',
  currently_unavailable: 'Currently not available',
  forgot_password: 'Forgot your password? Click here.',
  reset_password: 'Reset password',
  send_reset_instructions: 'Send reset email',
  toast_reset_email_sent: 'Password reset email has been sent!',
  confirm_password: 'Confirm password',
  password_match: 'Passwords must match',
  email_not_registered:
    'There is no account associated with this email address',
  toast_password_reset: 'Password has been changed',
  privacy_policy: 'Privacy policy',
  profile: 'Account',
  previous_orders: 'Previous orders',
  change_password: 'Change password',
  account_information: 'Personal information',
  addresses: 'Addresses',
  previous_password: 'Previous password',
  previous_password_required: 'Previous password is required',
  previous_password_incorrect: 'Previous password is wrong',
  edit: 'Edit',
  delete: 'Delete',
  toast_address_edited: 'The address has been edited',
  toast_address_deleted: 'The address has been deleted',
  confirm_delete_address: 'Are you sure you want to delete this address?',
  save_short: 'Save',
  when_buying: 'When buying ',
  or_more: 'or more',
  delete_account: 'Delete account',
  confirm_delete_account:
    'Are you sure you want to delete your account? The action is irreversible and you will not be able to access your account anymore.',
  toast_account_deleted: 'Account has been deleted',
  cart: 'Cart',
  delivery: 'Delivery',
  payment: 'Payment',
  next_working_day: 'Next workday',
  pickup_point: 'DPD pickup point',
  pickup_point_delivery_time: '2 - 3 days',
  courrier_delivery_time: '2 - 3 days',
  select_point: 'Select pickup point',
  to_delivery: 'Choose delivery',
  to_payment: 'Go to payment',
  toast_select_delivery_method: 'Select a delivery method!',
  toast_select_pickup_point: 'Select a pickup point!',
  toast_select_address: 'Select an address!',
  to_distance_agreement: 'Distance agreement',
  agree_terms: 'I have read and agree with the ',
  pay: 'Pay',
  toast_must_agree: 'You must agree with the terms to complete the order!',
  related_products: 'Related products',
  start_over_payment: 'Start over with payment',
  payment_failed: 'Payment failed, please start over',
  email_verified: 'Email verified',
  can_start_shopping:
    'Your email has been verified.\n\nYou can start shopping now!',
  order_completed: 'Order completed',
  order_completed_info:
    'We have received your order and will begin packing it shortly.\n\nIf there are any issues, we will contact you via email.\n\nWe have also sent you an email containing the receipt for this order.',
  search: 'Search',
  image: 'Image',
  price_per_one: 'One costs',
  when_buying_x: 'When buying x',
  phone_use_info:
    'You will receive all necessary information by email.\nYou can optionally give your phone number for easier communication.',
  delivery_bakery: 'Pick up at the bakery',
  delivery_courrier: 'DPD Courrier delivery',
  delivery_pickupPoint: 'DPD pickup point',
  delivery_time_bakery: 'Next workday',
  delivery_time_courrier: '2 - 7 days',
  delivery_time_pickupPoint: '2 - 7 days',
  toast_pickup_point_data:
    'Please fill in all required data for pickup point delivery',
  price_mismatch:
    'There was a mismatch between the cart total and the paid amount.\nWe will contact you via email to resolve the issue.',
  toast_price_mismatch: 'Problem with paid amount',
  to_comments: 'Add comments',
  continue: 'Proceed',
  comments: 'Comments',
  businessComments: 'Business details for corporate clients',
  businessComments_label: 'Name, address, Reg.nr...',
  generalComments: 'Comments for Flora Bakery',
  generalComments_label: 'Comment',
  deliveryComments: 'Comments about the delivery',
  deliveryComments_label: 'Comment',
  sorting: 'Sorting',
  select_category: 'Select a category',
  select_product: 'Select a product',
  banner: 'Banner',
  links_to: 'Links to ',
  links_to_nowhere: 'No link',
  enter_captcha: 'Captcha',
  captcha_mismatch: 'Captcha incorrect',
  toast_contact_form_success: 'Message sent!',
  settings: 'Settings',
  contact_form_emails: 'Contact form receivers',
  order_notification_emails: 'Order notification receivers',
  delivery_short_bakery: 'Bakery',
  delivery_short_courrier: 'Courrier',
  delivery_short_pickupPoint: 'Pickup point',
  post_index: 'Postal code',
  vat: 'VAT',
  request_courrier: 'Request courrier',
  handed_to_courrier: 'Given to courrier',
  create_shipment: 'Create shipment',
  company_name: 'Company name',
  contact_info: 'Contact info',
  catalogue: 'Catalogue',
  unlisted: 'Invisible',
  unavailable: 'Unavailable',
  uncategorized: 'Uncategorized',
  active: 'Active',
  inactive: 'Inactive',
  new_category: 'New category',
  page_not_found: 'Page not found',
  to_home: 'To homepage',
  reason: 'Reason',
  delivered: 'Delivered',
  picked_up: 'Picked up',
  confirm_make_completed: 'Is the order completed?',
  quantity: 'pcs.',
  resend_receipt: 'Resend receipt email',
  special_order:
    'Prepared upon request.\nThe order will be done within 10 working days.',
  latvia: 'Latvia',
  country: 'Country',
  address: 'Address',
  regNo: 'Reg. No.',
  pay_now: 'Pay now',
  toast_incomplete_business_comments: 'Business details incomplete',
  online_payment: 'Payment',
  receipt_payment: 'Invoice payment',
  receive_receipt: 'Receive invoice',
  receipt_instructions:
    'An invoice will be sent to your email.\nPacking will begin only after receival of payment.',
  online_payment_instructions: 'Payment with online banking or payment card',
  latvia_delivery: 'Currently delivering only within Latvia.',
  vatNo: 'VAT reg. No.',
  paid: 'Paid',
  order_invoiced: 'Invoice sent',
  order_invoiced_info:
    'We have sent you an invoice.\nWe will begin packing the order once payment is completed.\nThe invoice is valid for 7 days.',
  all: 'All',
  make_dpd_shipment: 'DPD shipment',
  make_own_shipment: 'Own shipment',
  businessCommentsShort: 'Business details',
  track: 'Track',
  filter: 'Filter',
  depending_on_delivery: 'Depending on delivery method',
  accumulated: 'Use',
  money: 'money',
  will_receive: 'Will earn',
  money_accusative: 'money',
  sum_too_low: 'Total cannot be negative',
  site_name: 'Bakery Flora',
  cert_no: 'Certificate No:',
  print: 'Print label',
  availability: 'Availability',
  slow_delivery: 'Slow delivery',
  invisible_in_search: 'Unsearchable',
  remove_from_category: 'Remove from category',
  discount_codes: 'Discount codes',
  discount_code: 'Discount code',
  discount_code_text: 'Code',
  discount_code_end_time: 'Expiry',
  discount_code_percent: 'Percent (%)',
  confirm_delete: 'Confirm deletion',
  toast_discount_code_activated: 'Discount code activated',
  code: 'Code',
  toast_email_verified: 'Email has been verified',
  didnt_receive_code: 'Did not receive a code?',
  send_new_code: 'Send new code',
  toast_code_is_sent: 'Code has been sent!',
  verify_email_tab: 'Verify email',
  valid_password: '✔ Valid password',
  account: 'Account',
  continue_without_account: 'Continue without registering',
  will_not_receive: '✘ You will not receive',
  toast_email_missing: 'Email is missing',
  email_explanation: 'Email is required for delivery.',
  and: 'and',
  to_privacy_policy: 'Privacy policy',
  vacancies: 'Careers',
  min_salary: 'Min salary (EUR)',
  max_salary: 'Max salary (EUR)',
  salary: 'Salary',
  no_vacancies: 'There are no openings currently.',
}

export default en
