import { useEffect, useState } from 'react'
import useVacancyService from '../../services/vacancy'
import VacancyList from './VacancyList'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Vacancy from './Vacancy'

const SingleVacancyPage = () => {
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const vacancyServive = useVacancyService()
  const [vacancy, setVacancy] = useState([])
  const title = useParams().title

  const refresh = () => {
    vacancyServive.get(title).then((data) => setVacancy(data))
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <div className="row center full-width">
      <div
        style={{ maxWidth: '800px', minWidth: 'min(100vw, 600px)' }}
        className="p">
        <Vacancy vacancy={vacancy} />
      </div>
    </div>
  )
}
export default SingleVacancyPage
