import useUserService from '../../services/user'
import useField from '../../hooks/useField'
import { useContext, useEffect, useState } from 'react'
import UserContext from '../../contexts/userContext'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import PasswordWithValidation from '../basic/PasswordValidation'
import Input from '../basic/Input'
import useToast from '../../util/promiseToast'
import { toast } from 'react-toastify'

const VerifyEmail = ({ navigateOnSuccess }) => {
  const userService = useUserService()
  const code = useField('text')
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const { showPromiseToast, showErrorToastNoPromise } = useToast()
  const navigate = useNavigate()
  const [user, setUser] = useContext(UserContext)
  let [searchParams, setSearchParams] = useSearchParams()

  const tryToVerifyEmail = () => {
    const promise = userService.tryToVerifyEmail(code.value)
    showPromiseToast({
      promise,
      successMessage: lang.toast_email_verified,
    })
    promise.then(
      (response) => {
        if (response.status === 200) {
          setUser({ ...user, emailVerified: true })
          if (navigateOnSuccess) {
            navigate(searchParams.cart ? '/order_process/delivery' : '/')
          }
        }
      },
      (error) => {}
    )
  }
  useEffect(() => {
    if (code.value.length >= 6) {
      const promise = userService.tryToVerifyEmail(code.value)

      promise.then(
        (response) => {
          if (response.status === 200) {
            toast.success(lang.toast_email_verified)
            setUser({ ...user, emailVerified: true })
            if (navigateOnSuccess) {
              navigate(
                searchParams.get('cart') ? '/order_process/delivery' : '/'
              )
            }
          }
        },
        (error) => {}
      )
    }
  }, [code.value])
  return (
    <div className="center-h">
      <div className="card">
        <h1 className="big-title m-b">{lang.verify_email}</h1>
        <p className="wrap-n card-text m-b">{lang.verification_instructions}</p>
        <div className="row align-cross-end center m-b ">
          <Input
            {...code}
            label={lang.code}
          />
          <button
            className="btn"
            onClick={tryToVerifyEmail}>
            {lang.continue}
          </button>
        </div>
        <div className="row m-b align-cross-end">
          <p>{lang.didnt_receive_code}</p>
          <button
            className="btn"
            onClick={() =>
              showPromiseToast({
                promise: userService.requestNewEmailToken(),
                successMessage: lang.toast_code_is_sent,
              })
            }>
            {lang.send_new_code}
          </button>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
