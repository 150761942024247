import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import useBannerService from '../../services/banners'
import { Cross } from '@styled-icons/entypo/Cross'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { useEffect, useState } from 'react'
import useField from '../../hooks/useField'
import Input from '../basic/Input'
import { useSelector } from 'react-redux'
import {
  gramsToKilos,
  toEnglishAlphabet,
  useDateTimeFormat,
} from '../../util/convert'
import useUploadService from '../../services/uploads'
import useCategoryService from '../../services/category'
import useProductService from '../../services/product'
import { CSS } from '@dnd-kit/utilities'
import { toast } from 'react-toastify'
import useDiscountCodeService from '../../services/discountCode'
import { Bin } from '@styled-icons/icomoon/Bin'

const DiscountCodeCard = ({
  text,
  description,
  endTime,
  percent,
  deleteCode,
}) => {
  const dateTimeFormatter = useDateTimeFormat()
  return (
    <tr className="card">
      <td className="title uppercase">{text}</td>
      <td className="card-text ">{description}</td>
      <td className="title  bad ">-{percent}%</td>
      <td className="card-text ">
        {dateTimeFormatter.format(new Date(endTime))}
      </td>
      <td>
        <button
          className="btn inverted cancel"
          onClick={() => deleteCode({ text })}>
          <Bin className="icon-m" />
        </button>
      </td>
    </tr>
  )
}
const NewDiscountCode = ({ createNew }) => {
  const selectedLang = useSelector((state) => state.lang.selectedLang)
  const text = useField('text')
  const description = useField('textarea')
  const endTime = useField('datetime-local')
  const percent = useField('number')
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])

  return (
    <div className="row align-cross-start card p between m-d">
      <Input
        {...text}
        label={lang.discount_code_text}
        style={{ textTransform: 'uppercase' }}
        required
      />
      <Input
        {...description}
        label={lang.description}
      />

      <Input
        {...percent}
        label={lang.discount_code_percent}
        required
      />
      <Input
        {...endTime}
        label={lang.discount_code_end_time}
        required
      />
      <button
        onClick={() =>
          createNew({
            text: text.value,
            description: description.value,
            endTime: endTime.value,
            percent: percent.value,
          })
        }
        className="btn self-stretch">
        {lang.create}
      </button>
    </div>
  )
}

const DiscountCodeManagement = () => {
  const disocuntCodeService = useDiscountCodeService()
  const [discountCodes, setDiscountCodes] = useState([])
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])

  const refresh = () => {
    disocuntCodeService
      .getAll()
      .then((data) => setDiscountCodes(data.discountCodes))
  }

  useEffect(() => {
    refresh()
  }, [])

  const createDiscountCode = ({ text, description, endTime, percent }) => {
    disocuntCodeService
      .createNew({ text, description, endTime, percent })
      .then((response) => {
        toast.success(lang.toast_changes_saved)
        refresh()
      })
  }

  const deleteDiscountCode = ({ text }) => {
    if (window.confirm(lang.confirm_delete)) {
      disocuntCodeService.deleteCode({ text }).then((response) => {
        toast.success(lang.toast_changes_saved)
        refresh()
      })
    }
  }

  return (
    <div className="m-t">
      <NewDiscountCode createNew={createDiscountCode} />
      <table className="table table full-width">
        {discountCodes.map((code) => (
          <DiscountCodeCard
            text={code.text}
            description={code.description}
            endTime={code.endTime}
            percent={code.percent}
            deleteCode={deleteDiscountCode}
          />
        ))}
      </table>
    </div>
  )
}
export default DiscountCodeManagement
