import axios from 'axios'
import makeConfig from './token'
import { apiURL } from '../util/config'
import useToast from '../util/promiseToast'

const useVacancyService = () => {
  const baseUrl = `${apiURL}/vacancies`
  const { showErrorToast } = useToast()

  const createNew = (vacancy) => {
    const config = makeConfig()
    const request = axios.post(`${baseUrl}`, vacancy, config)
    showErrorToast(request)
    return request.then((response) => response.data)
  }

  const deleteOne = (title) => {
    const config = makeConfig()
    const request = axios.delete(`${baseUrl}/${title}`, config)
    showErrorToast(request)
    return request.then((response) => response.data)
  }

  const getAll = () => {
    const config = makeConfig()
    const request = axios.get(`${baseUrl}/`, config)
    return request.then((response) => response.data)
  }
  const getAllAdmin = () => {
    const config = makeConfig()
    const request = axios.get(`${baseUrl}/all`, config)
    return request.then((response) => response.data)
  }

  const get = (title) => {
    const config = makeConfig()
    const request = axios.get(`${baseUrl}/${title}`, config)
    return request.then((response) => response.data)
  }

  return {
    createNew,
    getAll,
    deleteOne,
    get,
    getAllAdmin,
  }
}
export default useVacancyService
