import { useDispatch, useSelector } from 'react-redux'
import Input from '../basic/Input'
import useField from '../../hooks/useField'
import { useEffect } from 'react'
import { useCartServiceDispatch } from '../../reducers/cartReducer'
import SignUp from '../user/SignUp'
import { useSearchParams } from 'react-router-dom'
import Login from '../user/Login'

const AccountChoice = ({ runChecksAndNavigate }) => {
  const email = useField('email')
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const { updateEmail } = useCartServiceDispatch()
  const dispatch = useDispatch()
  const savedEmail = useSelector((state) => state.cart.email)
  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (savedEmail) {
      email.changeValue(savedEmail)
    }
  }, [savedEmail])
  return (
    <div className="row around align-cross-stretch">
      {searchParams.get('login') ? (
        <Login fromCart={true} />
      ) : (
        <SignUp fromCart={true} />
      )}
      <div
        className="card column "
        style={{ flexGrow: 0 }}>
        <h3 className="big-title m-t-b">{lang.continue_without_account}</h3>
        <Input
          {...email}
          className="m-h-a m-t-m"
          required={true}
          onBlur={() => {
            dispatch(updateEmail(email.value))
          }}
          label={lang.email}
        />
        <p className=" m-h-a hint-text">{lang.email_explanation}</p>
        <div className=" float-to-end" />
        <button
          className="btn m-b m-d-s"
          onClick={runChecksAndNavigate}>
          {lang.continue}
        </button>
        <p className="m-b m-t-0 m-h-a bad">
          {lang.will_not_receive}
          <img
            src="https://maiznica.lv/images/logo_no_subtitle.png"
            className="logo-inline m-h-s "
          />
          {lang.money_accusative}
        </p>
      </div>
    </div>
  )
}
export default AccountChoice
