import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import useBannerService from '../../services/banners'
import { Cross } from '@styled-icons/entypo/Cross'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { useEffect, useState } from 'react'
import useField from '../../hooks/useField'
import Input from '../basic/Input'
import { useSelector } from 'react-redux'
import { gramsToKilos, toEnglishAlphabet } from '../../util/convert'
import useUploadService from '../../services/uploads'
import useCategoryService from '../../services/category'
import useProductService from '../../services/product'
import { CSS } from '@dnd-kit/utilities'
import { toast } from 'react-toastify'
import Editor from 'react-simple-wysiwyg'
import Vacancy from '../vacancies/Vacancy'
import useVacancyService from '../../services/vacancy'
import VacancyList from '../vacancies/VacancyList'

const NewVacancy = ({ vacancy, setVacancy, createVacancy }) => {
  const selectedLang = useSelector((state) => state.lang.selectedLang)
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const title = useField('text')
  const salaryMin = useField('number')
  const salaryMax = useField('number')

  return (
    <div className="column card p p-t-0 between">
      <Input
        label={lang.product_name}
        {...title}
        value={vacancy.title}
        onChange={(e) => {
          setVacancy({ ...vacancy, title: e.target.value })
        }}
      />{' '}
      <Input
        label={lang.min_salary}
        {...salaryMin}
        value={vacancy.salaryMin}
        onChange={(e) => {
          setVacancy({ ...vacancy, salaryMin: e.target.value })
        }}
      />{' '}
      <Input
        label={lang.max_salary}
        {...salaryMax}
        value={vacancy.salaryMax}
        onChange={(e) => {
          setVacancy({ ...vacancy, salaryMax: e.target.value })
        }}
      />{' '}
      <Editor
        value={vacancy.text}
        onChange={(e) => {
          setVacancy({ ...vacancy, text: e.target.value })
          console.log(vacancy)
        }}
      />
      <button
        onClick={() => {
          createVacancy()
        }}
        className="btn">
        {lang.create}
      </button>
    </div>
  )
}

const VacancyManagement = () => {
  const vacancyServive = useVacancyService()
  const [vacancies, setVacancies] = useState([])
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const [vacancy, setVacancy] = useState({ title: '', text: '' })
  const refresh = () => {
    vacancyServive.getAllAdmin().then((data) => setVacancies(data.vacancies))
  }

  useEffect(() => {
    refresh()
  }, [])

  const createVacancy = () => {
    console.log(vacancy)

    vacancyServive.createNew(vacancy).then((data) => {
      setVacancies(data.vacancies)
      toast.success(lang.toast_changes_saved)
    })
  }

  const deleteVacancy = (title) => {
    if (window.confirm('Tiešām dzēst?')) {
      vacancyServive.deleteOne(title).then((data) => {
        setVacancies(data.vacancies)
        toast.success(lang.toast_changes_saved)
      })
    }
  }

  const editVacancy = (vacancy) => {
    setVacancy(vacancy)
  }

  const toggleVisibility = (vacancy) => {
    vacancyServive
      .createNew({ ...vacancy, visible: !vacancy.visible })
      .then((data) => {
        setVacancies(data.vacancies)
        toast.success(lang.toast_changes_saved)
      })
  }

  return (
    <div
      className="m-t"
      style={{ position: 'absolute', left: 'var(--space)', width: '100%' }}>
      <div className="row between m-d no-wrap">
        <div className="column">
          <VacancyList
            vacancies={vacancies}
            management={true}
            deleteVacancy={deleteVacancy}
            editVacancy={editVacancy}
            toggleVisibility={toggleVisibility}
          />
        </div>
        <div
          className="column"
          style={{ minWidth: '600px' }}>
          <NewVacancy
            setVacancy={setVacancy}
            vacancy={vacancy}
            createVacancy={createVacancy}
          />
        </div>
        <div className="column">
          <Vacancy vacancy={vacancy} />
        </div>
      </div>
    </div>
  )
}
export default VacancyManagement
