const ButtonSpinner = () => {
  return (
    <div className="spinner spinner-small column align-cross-center">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
export default ButtonSpinner
