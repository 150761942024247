import { Link, useMatch, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Edit } from '@styled-icons/evaicons-solid/Edit'
import { BoxSeam } from '@styled-icons/bootstrap/BoxSeam'
import { Eye } from '@styled-icons/heroicons-solid/Eye'
import { gramsToKilos } from '../../util/convert'
import { Cross } from '@styled-icons/entypo/Cross'

const CompactProductItem = ({
  product,
  toggleShow,
  toggleStock,
  remove,
  eis,
}) => {
  const selectedLang = useSelector((state) => state.lang.selectedLang)
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  return (
    <tr className="m-d">
      <td>
        <Link to={`/products/${product.prettyID}`}>
          <img
            className="image-xs"
            src={`https://www.maiznica.lv/images/xs_${product.image}`}
          />
        </Link>
      </td>
      <td>
        <Link
          to={`/products/${product.prettyID}`}
          className={`p-v ${product.invisible && 'unavailable'} ${
            product.outOfStock && 'slow_delivery'
          }`}
          style={{}}>
          <h3 className="product-title-small ">
            {product.name[selectedLang] || product.name.lv}{' '}
            {gramsToKilos(product.weight)}
          </h3>
        </Link>
      </td>
      <td className="p-0">
        <button
          className={`btn inverted icon-button p ${
            product.invisible && 'subtler'
          } `}
          onClick={() => {
            toggleShow(product)
          }}
          title={lang.availability}>
          <Eye className="icon-m " />
        </button>
      </td>
      <td className="p-0">
        <button
          className={`btn inverted icon-button p ${
            product.outOfStock && 'subtler'
          } `}
          onClick={() => {
            toggleStock(product)
          }}
          title={lang.slow_delivery}>
          <BoxSeam className="icon-m" />
        </button>
      </td>
      <td className="p-0">
        <Link to={`/management/new_product/${product.id}`}>
          <button className="btn inverted icon-button p">
            <Edit className="icon-m" />
          </button>
        </Link>
      </td>
      {eis && (
        <td className="p-0">
          <Link to={`/products/${product.prettyID}?eis=true`}>
            <h3 className="product-title-small p">EIS</h3>
          </Link>
        </td>
      )}
      {remove && (
        <td className="p-0">
          <button
            className="btn inverted icon-button cancel p"
            onClick={(e) => {
              e.preventDefault()
              remove()
            }}
            title={lang.remove_from_category}>
            <Cross className="icon-m" />
          </button>
        </td>
      )}
    </tr>
  )
}

export default CompactProductItem
