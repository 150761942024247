import { Link, NavLink, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProductManagementPage = () => {
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  return (
    <div>
      <div className="header sub-header card">
        <NavLink
          className="tab"
          to="complete">
          {lang.all}
        </NavLink>
        <NavLink
          className="tab"
          to="unavailable">
          {lang.unavailable}
        </NavLink>
        <NavLink
          className="tab"
          to="slow_delivery">
          {lang.slow_delivery}
        </NavLink>
      </div>

      <Outlet />
    </div>
  )
}
export default ProductManagementPage
