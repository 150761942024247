import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Link, useMatch, useParams } from 'react-router-dom'
import CategoryModal from './CategoryModal'
import ProductModal from './ProductModal'
import { useSelector } from 'react-redux'
import useCategoryService from '../../services/category'
import { Edit } from '@styled-icons/evaicons-solid/Edit'
import { Cross } from '@styled-icons/entypo/Cross'
import { BoxSeam } from '@styled-icons/bootstrap/BoxSeam'
import useProductService from '../../services/product'
import { Eye } from '@styled-icons/heroicons-solid/Eye'
import CompactProductItem from '../productList/CompactProductItem'
import useToast from '../../util/promiseToast'

const ProductItem = ({
  depth,
  product,
  parentCategory,
  removeProduct,
  toggleShow,
  toggleStock,
}) => {
  const selectedLang = useSelector((state) => state.lang.selectedLang)
  return (
    // <>
    //   <div
    //     className="row no-gap"
    //     style={{ marginLeft: `calc(${depth} * 20px)` }}>
    //     <div className="depth-indicators">
    //       {[...Array(depth)].map((e, i) => (
    //         <div className="depth-indicator" />
    //       ))}
    //     </div>

    //   </div>
    <CompactProductItem
      product={product}
      toggleShow={toggleShow}
      toggleStock={toggleStock}
      remove={() => {
        removeProduct(product.id, parentCategory.id)
      }}
    />
    // </>
  )
}

const CategoryItem = ({
  depth,
  category,
  removeCategory,
  parentCategory,
  handleCategoryButton,
  handleProductButton,
  toggleShowCategory,
}) => {
  const selectedLang = useSelector((state) => state.lang.selectedLang)
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  return (
    <div>
      {category.displayName && (
        <div className="row no-gap">
          <Link
            to={`/category/${category.id}`}
            className={category.invisible && 'unavailable'}>
            <span className="card-heading">
              {category.displayName[selectedLang] || category.displayName.lv}
            </span>
          </Link>
          {category.id !== 'all' && category.id !== 'new' && (
            <>
              {parentCategory && parentCategory.id && (
                <button
                  className="btn inverted icon-button m-h-s cancel"
                  onClick={(e) => {
                    e.preventDefault()
                    removeCategory(category.id, parentCategory.id)
                  }}>
                  <Cross
                    className="icon-m"
                    title={lang.remove_from_category}
                  />
                </button>
              )}
              <Link to={`/management/new_category/${category.id}`}>
                <button className="btn inverted icon-button m-h-s">
                  <Edit className="icon-m" />
                </button>
              </Link>

              <button
                className={`btn inverted icon-button  ${
                  category.invisible && 'subtler'
                } `}
                onClick={() => {
                  toggleShowCategory(category)
                }}
                title={lang.invisible_in_search}>
                <Eye className="icon-m " />
              </button>
            </>
          )}

          <button
            className="btn m-h"
            onClick={handleProductButton}>
            + {lang.products}
          </button>
          <button
            className="btn"
            onClick={handleCategoryButton}>
            + {lang.category}
          </button>
        </div>
      )}
    </div>
  )
}

const CategoryTab = ({
  category,
  handleCategory,
  handleProduct,
  removeProduct,
  removeCategory,
  parentCategory,
  toggleShow,
  toggleStock,
  toggleShowCategory,
  depth,
}) => {
  const selectedLang = useSelector((state) => state.lang.selectedLang)
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])

  const handleCategoryButton = () => {
    handleCategory(category)
  }
  const handleProductButton = () => {
    handleProduct(category)
  }

  return (
    <div className="card p p-h-b m-v">
      <CategoryItem
        depth={depth}
        category={category}
        removeCategory={removeCategory}
        parentCategory={parentCategory}
        handleCategoryButton={handleCategoryButton}
        handleProductButton={handleProductButton}
        toggleShowCategory={toggleShowCategory}
      />
      <div className="order-table cart-table">
        {category.products &&
          category.products.map((product) => (
            <ProductItem
              depth={depth + 1}
              key={product.id}
              product={product}
              parentCategory={category}
              removeProduct={removeProduct}
              toggleShow={toggleShow}
              toggleStock={toggleStock}
            />
          ))}
      </div>
      {category.categories &&
        category.categories.map((c) => (
          <CategoryTab
            depth={depth + 1}
            key={c.id}
            category={c}
            handleCategory={handleCategory}
            handleProduct={handleProduct}
            removeProduct={removeProduct}
            toggleShow={toggleShow}
            toggleStock={toggleStock}
            removeCategory={removeCategory}
            parentCategory={category}
            toggleShowCategory={toggleShowCategory}
          />
        ))}
    </div>
  )
}

const CategoryManagement = ({}) => {
  const match = useMatch('/management/categories/:category')
  const category = match.params.category

  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const { showPromiseToast } = useToast()
  const categoryService = useCategoryService()
  const productService = useProductService()
  const [catalogue, setCatalogue] = useState(null)
  const [activeCategory, setActiveCategory] = useState(null)
  const [addingProduct, setAddingProduct] = useState(false)
  const [addingCategory, setAddingCategory] = useState(false)

  const clickCategory = (category) => {
    setActiveCategory(category)
    setAddingCategory(true)
  }
  const clickProduct = (category) => {
    setActiveCategory(category)
    setAddingProduct(true)
  }

  const onModalClose = () => {
    setActiveCategory(null)
    setAddingCategory(false)
    setAddingProduct(false)
    refresh()
  }

  const removeProduct = (id, parentCategory) => {
    categoryService
      .removeProducts({ productId: id, parentCategory })
      .then(() => refresh())
  }
  const removeCategory = (id, parentCategory) => {
    categoryService
      .removeCategories({ categoryId: id, parentCategory })
      .then(() => refresh())
  }
  const toggleShow = (product) => {
    if (product.invisible) {
      showPromiseToast({
        promise: productService.showProduct(product.id).then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    } else {
      showPromiseToast({
        promise: productService.hideProduct(product.id).then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    }
  }
  const toggleShowCategory = (category) => {
    if (category.invisible) {
      showPromiseToast({
        promise: categoryService
          .showCategory(category.id)
          .then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    } else {
      showPromiseToast({
        promise: categoryService
          .hideCategory(category.id)
          .then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    }
  }

  const toggleStock = (product) => {
    if (product.outOfStock) {
      showPromiseToast({
        promise: productService.inStock(product.id).then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    } else {
      showPromiseToast({
        promise: productService.outOfStock(product.id).then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    }
  }
  const refresh = () => {
    categoryService.getCategory(category).then((result) => {
      setCatalogue(result)
    })
  }

  useEffect(() => {
    refresh()
  }, [category])

  return (
    <>
      <CategoryModal
        visible={addingCategory}
        activeCategory={activeCategory}
        onClose={onModalClose}
        catalogue={catalogue}
      />
      <ProductModal
        visible={addingProduct}
        activeCategory={activeCategory}
        onClose={onModalClose}
      />
      <ul style={{ listStyle: 'none', paddingInlineStart: '0' }}>
        {catalogue ? (
          <CategoryTab
            depth={0}
            handleProduct={clickProduct}
            handleCategory={clickCategory}
            removeProduct={removeProduct}
            toggleShow={toggleShow}
            toggleStock={toggleStock}
            removeCategory={removeCategory}
            toggleShowCategory={toggleShowCategory}
            category={catalogue}
          />
        ) : (
          <p>wait</p>
        )}
      </ul>
    </>
  )
}
export default CategoryManagement
