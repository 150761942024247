import { useEffect, useState } from 'react'

import { Link, useMatch, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Edit } from '@styled-icons/evaicons-solid/Edit'
import { BoxSeam } from '@styled-icons/bootstrap/BoxSeam'
import useProductService from '../../services/product'
import { Eye } from '@styled-icons/heroicons-solid/Eye'
import { gramsToKilos } from '../../util/convert'
import { toast } from 'react-toastify'
import useToast from '../../util/promiseToast'
import CompactProductItem from '../productList/CompactProductItem'

const CategoryTab = ({
  category,
  removeProduct,
  toggleShow,
  toggleStock,
  depth,
}) => {
  return (
    <div>
      <div className="order-table cart-table">
        {category.products &&
          category.products.map((product) => (
            <CompactProductItem
              depth={depth + 1}
              key={product.id}
              product={product}
              parentCategory={category}
              removeProduct={removeProduct}
              toggleShow={toggleShow}
              toggleStock={toggleStock}
              eis={true}
            />
          ))}
      </div>
    </div>
  )
}

const ProductManagement = ({}) => {
  const match = useMatch('/management/products/:category')
  const category = match.params.category
  const productService = useProductService()
  const [catalogue, setCatalogue] = useState(null)
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const { showPromiseToast } = useToast()
  const toggleShow = (product) => {
    if (product.invisible) {
      showPromiseToast({
        promise: productService.showProduct(product.id).then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    } else {
      showPromiseToast({
        promise: productService.hideProduct(product.id).then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    }
  }

  const toggleStock = (product) => {
    if (product.outOfStock) {
      showPromiseToast({
        promise: productService.inStock(product.id).then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    } else {
      showPromiseToast({
        promise: productService.outOfStock(product.id).then(() => refresh()),
        successMessage: lang.toast_changes_saved,
      })
    }
  }

  const load = () => {
    productService.getAll(category).then((result) => {
      setCatalogue({
        products: result.sort((a, b) => {
          a = a.name.lv.replace(/\W/g, '')
          b = b.name.lv.replace(/\W/g, '')
          if (a < b) {
            return -1
          } else if (a > b) {
            return 1
          }
          return 0
        }),
      })
    })
  }

  const refresh = () => {
    load()
  }

  useEffect(() => {
    load()
  }, [category])

  return (
    <>
      <ul style={{ listStyle: 'none', paddingInlineStart: '0' }}>
        {catalogue ? (
          <CategoryTab
            depth={0}
            toggleShow={toggleShow}
            toggleStock={toggleStock}
            category={catalogue}
          />
        ) : (
          <p>wait</p>
        )}
      </ul>
    </>
  )
}
export default ProductManagement
