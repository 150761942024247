import { Link } from 'react-router-dom'
import Vacancy from './Vacancy'
import { Cross } from '@styled-icons/entypo/Cross'
import useVacancyService from '../../services/vacancy'
import { Edit } from '@styled-icons/evaicons-solid/Edit'
import { Eye } from '@styled-icons/heroicons-solid/Eye'
import { useSelector } from 'react-redux'

const VacancyList = ({
  vacancies,
  management,
  deleteVacancy,
  editVacancy,
  toggleVisibility,
}) => {
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])

  return (
    <div className="column gap">
      {vacancies.length ? (
        <>
          {' '}
          {vacancies.map((vacancy) => (
            <Link to={management ? '' : vacancy.title}>
              <div
                key={vacancy.title}
                className="row no-wrap card p-m between">
                <h3 className="title ">{vacancy.title}</h3>
                <h3 className="title accented">
                  &euro;{vacancy.salaryMin}-&euro;{vacancy.salaryMax}
                </h3>
                {management && (
                  <button
                    className={`btn inverted icon-button ${
                      !vacancy.visible && 'subtler'
                    } `}
                    onClick={() => toggleVisibility(vacancy)}>
                    <Eye className="icon-m" />
                  </button>
                )}
                {management && (
                  <button
                    className="btn inverted icon-button "
                    onClick={() => editVacancy(vacancy)}>
                    <Edit className="icon-m" />
                  </button>
                )}
                {management && (
                  <button
                    data-no-dnd="true"
                    onClick={() => deleteVacancy(vacancy.title)}
                    className="btn icon-button inverted cancel">
                    <Cross className="icon-b" />
                  </button>
                )}
              </div>
            </Link>
          ))}
        </>
      ) : (
        <span className="card-text full-width text-center m-t-b">
          {lang.no_vacancies}
        </span>
      )}
    </div>
  )
}
export default VacancyList
